// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBan9EBetMHzeZOh1E1GuNynqa6OS8xzF0",
  authDomain: "ecommerceleadgeneration.firebaseapp.com",
  projectId: "ecommerceleadgeneration",
  storageBucket: "ecommerceleadgeneration.appspot.com",
  messagingSenderId: "726236346780",
  appId: "1:726236346780:web:a3004947605e9951e33059"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;