// import React from "react";
// import {
//   useCreateUserWithEmailAndPassword,
//   useSignInWithGoogle,
//   useUpdateProfile,
// } from "react-firebase-hooks/auth";
// import { useForm } from "react-hook-form";
// import { Link, useNavigate } from "react-router-dom";
// import auth from "../firebase.init";
// import Loading from "../components/Shared/Loading";

// const Register = () => {
//   const [signInWithGoogle, gUser, gLoading, gError] = useSignInWithGoogle(auth);
//   const {
//     register,
//     formState: { errors },
//     handleSubmit,
//   } = useForm();

//   const [createUserWithEmailAndPassword, user, loading, error] =
//     useCreateUserWithEmailAndPassword(auth);
//   const [updateProfile, updating, updateError] = useUpdateProfile(auth);
//   const navigate = useNavigate();

//   let signInError;

//   const onSubmit = async (data) => {
//     await createUserWithEmailAndPassword(data.email, data.password);
//     await updateProfile({ displayName: data.name });
//     console.log("Update done");
//     navigate("/");
//   };
//   if (loading || gLoading || updating) {
//     return <Loading />;
//   }
//   if (error || gError || updateError) {
//     signInError = (
//       <p className="text-red-500 text-center">
//         <small>
//           {error?.message || gError?.message || updateError?.message}
//         </small>
//       </p>
//     );
//   }
//   return (
//     <>
//       <div className="account-section bg-light-2 section-padding-120">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
//               <div className="account-heading-block">
               
//                 <div className="account-heading">
//                   <h2 className="account-heading__title heading-md">
//                     Create an account
//                   </h2>
//                   <p>Enter the information below to create your account</p>
//                 </div>
//               </div>
//               <form className="account_comment-box" onSubmit={handleSubmit(onSubmit)}>
//                 <div className="account_comment-box__form-inner">
//                   <div className="account_comment-box__form-input">
//                     <h2 className="account-title">Your Name</h2>
//                     <input
//                       type="text"
//                       id="name"
//                       placeholder="Your Name"
//                       className={`form-control bg-white ${errors.name ? "is-invalid" : ""}`}
//                       {...register("name", {
//                         required: {
//                           value: true,
//                           message: "Name is Required",
//                         },
//                       })}
//                     />
//                     {errors.name && (
//                       <div className="invalid-feedback">{errors.name.message}</div>
//                     )}
//                   </div>
//                   <div className="account_comment-box__form-input">
//                     <h2 className="account-title">Email Address</h2>
//                     <input
//                       type="email"
//                       id="email"
//                       placeholder="Your Email"
//                       className={`form-control bg-white ${errors.email ? "is-invalid" : ""}`}
//                       {...register("email", {
//                         required: {
//                           value: true,
//                           message: "Email is Required",
//                         },
//                         pattern: {
//                           value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
//                           message: "Provide a valid Email",
//                         },
//                       })}
//                     />
//                     {errors.email && (
//                       <div className="invalid-feedback">{errors.email.message}</div>
//                     )}
//                   </div>
//                   <div className="account_comment-box__form-input">
//                     <h2 className="account-title">Password*</h2>
//                     <input
//                       type="password"
//                       id="password"
//                       placeholder="Password"
//                       className={`form-control bg-white ${errors.password ? "is-invalid" : ""}`}
//                       {...register("password", {
//                         required: {
//                           value: true,
//                           message: "Password is Required",
//                         },
//                         minLength: {
//                           value: 6,
//                           message: "Must be 6 characters or longer",
//                         },
//                       })}
//                     />
//                     {errors.password && (
//                       <div className="invalid-feedback">{errors.password.message}</div>
//                     )}

//                   </div>
//                   {signInError && <div className="text-danger">{signInError}</div>}
                  
//                   <div className="account_comment-box__form-input-button">
//                     <button type="submit" className="btn-masco rounded-pill w-100">
//                       Create Account
//                     </button>
//                     <div className="button-bottom-text">
//                       <span>
//                         Already have an account?
//                         <Link to="/login">Sign in now</Link>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//     </>
//   );
// };

// export default Register;
import React from "react";
import {
  useCreateUserWithEmailAndPassword,
  useSignInWithGoogle,
  useUpdateProfile,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import auth from "../firebase.init";
import Loading from "../components/Shared/Loading";
import { toast } from "react-toastify";

const Register = () => {
  const [signInWithGoogle, gUser, gLoading, gError] = useSignInWithGoogle(auth);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);
  const [updateProfile, updating, updateError] = useUpdateProfile(auth);
  const navigate = useNavigate();

  let signInError;

  const customErrorMessage = (message) => {
    switch (message) {
      case 'Firebase: Error (auth/email-already-in-use).':
        return 'This email is already in use.';
      case 'Firebase: Error (auth/invalid-email).':
        return 'The email address is not valid.';
      case 'Firebase: Error (auth/weak-password).':
        return 'The password is too weak.';
      default:
        return message;
    }
  };

  const onSubmit = async (data) => {
    await createUserWithEmailAndPassword(data.email, data.password);
    await updateProfile({ displayName: data.name });
   toast.success("Account Create successfully");
    navigate("/user-dashboard");
    window.location.reload(); // Reload the page
  };

  if (loading || gLoading || updating) {
    return <Loading />;
  }

  if (error || gError || updateError) {
    signInError = (
      <p className="text-red-500 text-center">
        <small>
          {customErrorMessage(error?.message) || customErrorMessage(gError?.message) || customErrorMessage(updateError?.message)}
        </small>
      </p>
    );
  }

  return (
    <>
      <div className="account-section bg-light-2 section-padding-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
              <div className="account-heading-block">
                <div className="account-heading">
                  <h2 className="account-heading__title heading-md">Create an account</h2>
                  <p>Enter the information below to create your account</p>
                </div>
              </div>
              <form className="account_comment-box" onSubmit={handleSubmit(onSubmit)}>
                <div className="account_comment-box__form-inner">
                  <div className="account_comment-box__form-input">
                    <h2 className="account-title">Your Name</h2>
                    <input
                      type="text"
                      id="name"
                      placeholder="Your Name"
                      className={`form-control bg-white ${errors.name ? "is-invalid" : ""}`}
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Name is Required",
                        },
                      })}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name.message}</div>
                    )}
                  </div>
                  <div className="account_comment-box__form-input">
                    <h2 className="account-title">Email Address</h2>
                    <input
                      type="email"
                      id="email"
                      placeholder="Your Email"
                      className={`form-control bg-white ${errors.email ? "is-invalid" : ""}`}
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Email is Required",
                        },
                        pattern: {
                          value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                          message: "Provide a valid Email",
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email.message}</div>
                    )}
                  </div>
                  <div className="account_comment-box__form-input">
                    <h2 className="account-title">Password*</h2>
                    <input
                      type="password"
                      id="password"
                      placeholder="Password"
                      className={`form-control bg-white ${errors.password ? "is-invalid" : ""}`}
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Password is Required",
                        },
                        minLength: {
                          value: 6,
                          message: "Must be 6 characters or longer",
                        },
                      })}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password.message}</div>
                    )}
                  </div>
                  {signInError && <div className="text-danger">{signInError}</div>}
                  <div className="account_comment-box__form-input-button">
                    <button type="submit" className="btn-masco rounded-pill w-100">Create Account</button>
                    <div className="button-bottom-text">
                      <span>Already have an account?<Link to="/login">Sign in now</Link></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
