// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";

// const About = () => {
//   const { id } = useParams();
//   const [abouts, setAbouts] = useState([]);
//   useEffect(() => {
//     fetch(`https://ecommerceleadgenerationtools-d33f84638efb.herokuapp.com/about-services`)
//       .then((res) => res.json())
//       .then((info) => setAbouts(info));
//   }, [id]);

//   return (
//     <>
//       <div className="home-1_content-section-1 section-padding-120" id="about">
//         <div className="container">
//           <div className="row row--custom">
//             <div
//               className="offset-xl-1 col-lg-5 col-auto"
//               data-aos-duration={1000}
//               data-aos="fade-left"
//             >
//               <div className="home-1_content-image-1 content-image--mobile-width">
//                 <img
//                   src="./image/home-1/content-image-1.png"
//                   alt="alternative text"
//                 />
//                 <div className="home-1_content-image-1-shape">
//                   <img
//                     src="./image/home-1/content-image-1-shape.svg"
//                     alt="alternative text"
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               className="col-xl-6 col-lg-7 col-md-10 col-auto"
//               data-aos-duration={1000}
//               data-aos="fade-right"
//             >
//               <div className="content">
//                 <div className="content-text-block">
//                   <h2 className="content-title heading-md text-black">
//                     Boost the effectiveness of your promotions as well as polish your
//                     branding
//                   </h2>
//                   <p>
//                     SINCE 1998, we transform bold business ideas into exceptional
//                     digital products. We ideate, design, and develop data-driven
//                     digital products made to answer business challenges.
//                   </p>
//                   <p>
//                     We offer 360° services to smoothly guide you on your way to
//                     creating a seamless digital masterpiece projects on budget and on
//                     time.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="home-1_content-section-2 padding-bottom-120">
//         <div className="container">
//           <div className="row row--custom">
//             <div
//               className="col-xl-5 col-lg-5 col-auto"
//               data-aos-duration={1000}
//               data-aos="fade-right"
//             >
//               <div className="home-1_content-image-2 content-image--mobile-width">
//                 <img
//                   src="./image/home-1/content-image-2.png"
//                   alt="alternative text"
//                 />
//                 <div className="home-1_content-image-2-shape">
//                   <img
//                     src="./image/home-1/content-image-2-shape.svg"
//                     alt="alternative text"
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               className="offset-xl-1 col-xl-6 col-lg-7 col-md-11"
//               data-aos-duration={1000}
//               data-aos="fade-left"
//             >
//               <div className="content">
//                 <div className="content-text-block">
//                   <h2 className="content-title heading-md text-black">
//                     Discover the latest digital strategies &amp; emerging ideas for
//                     business growth
//                   </h2>
//                   <p>
//                     Our brand tenders and marketing mixologists always serve up
//                     unique, design-forward websites coded with today’s modern
//                     technologies
//                   </p>
//                 </div>
//                 <div className="content-list-block">
//                   <ul className="content-list">
//                     <li className="content-list-item">
//                       <img
//                         src="./image/icons/icon-check-blue.svg"
//                         alt="alternative text"
//                       />
//                       Reach new business opportunities or test your product ideas.
//                     </li>
//                     <li className="content-list-item">
//                       <img
//                         src="./image/icons/icon-check-blue.svg"
//                         alt="alternative text"
//                       />
//                       Automate your processes and get data-driven business insights.
//                     </li>
//                     <li className="content-list-item">
//                       <img
//                         src="./image/icons/icon-check-blue.svg"
//                         alt="alternative text"
//                       />
//                       Create lightweight, scalable, and easly accessible cloud
//                       solution.
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default About;



import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const About = () => {
  const { id } = useParams();
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationtools-d33f84638efb.herokuapp.com/about-services`)
      .then((res) => res.json())
      .then((info) => setAbouts(info));
  }, [id]);

  return (
    <>
      {abouts.map((about, index) => (
        <div
          key={about._id}
          className={`home-1_content-section-${index + 1} ${
            index % 2 === 0 ? "section-padding-120" : "padding-bottom-120"
          }`}
          id={`about-${index}`}
        >
          <div className="container">
            <div className="row row--custom">
              {index % 2 === 0 ? (
                <>
                  <div
                    className="offset-xl-1 col-lg-5 col-auto"
                    data-aos-duration={1000}
                    data-aos="fade-left"
                  >
                    <div className="home-1_content-image-1 content-image--mobile-width">
                      <img
                        src={about.img}
                        alt="alternative text"
                      />
                      <div className="home-1_content-image-1-shape">
                        <img
                          src="./image/home-1/content-image-1-shape.svg"
                          alt="alternative text"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-7 col-md-10 col-auto"
                    data-aos-duration={1000}
                    data-aos="fade-right"
                  >
                    <div className="content">
                      <div className="content-text-block">
                        <h2 className="content-title heading-md text-black">
                          {about.title}
                        </h2>
                        <p>{about.description}</p>
                        <ul className="content-list mt-3">
                          <li className="content-list-item">
                            <img
                              src="./image/icons/icon-check-blue.svg"
                              alt="alternative text"
                            />
                            {about.pointOne}
                          </li>
                          <li className="content-list-item">
                            <img
                              src="./image/icons/icon-check-blue.svg"
                              alt="alternative text"
                            />
                            {about.pointTwo}
                          </li>
                          <li className="content-list-item">
                            <img
                              src="./image/icons/icon-check-blue.svg"
                              alt="alternative text"
                            />
                            {about.pointThree}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="col-xl-5 col-lg-5 col-auto"
                    data-aos-duration={1000}
                    data-aos="fade-right"
                  >
                    <div className="home-1_content-image-2 content-image--mobile-width">
                      <img
                        src={about.img}
                        alt="alternative text"
                      />
                      <div className="home-1_content-image-2-shape">
                        <img
                          src="./image/home-1/content-image-2-shape.svg"
                          alt="alternative text"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="offset-xl-1 col-xl-6 col-lg-7 col-md-11"
                    data-aos-duration={1000}
                    data-aos="fade-left"
                  >
                    <div className="content">
                      <div className="content-text-block">
                        <h2 className="content-title heading-md text-black">
                          {about.title}
                        </h2>
                        <p>{about.description}</p>
                        <ul className="content-list mt-3">
                          <li className="content-list-item">
                            <img
                              src="./image/icons/icon-check-blue.svg"
                              alt="alternative text"
                            />
                            {about.pointOne}
                          </li>
                          <li className="content-list-item">
                            <img
                              src="./image/icons/icon-check-blue.svg"
                              alt="alternative text"
                            />
                            {about.pointTwo}
                          </li>
                          <li className="content-list-item">
                            <img
                              src="./image/icons/icon-check-blue.svg"
                              alt="alternative text"
                            />
                            {about.pointThree}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default About;
